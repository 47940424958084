<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
@import './assets/styles/index';
html,body {
  background-color: #f0f2f5;
}
</style>

