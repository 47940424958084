import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// 创建一个新的 store 实例
const store = createStore({
  plugins: [createPersistedState({
	  storage: window.localStorage
  })],
  state () {
    return {
      userInfo: {
		  name: "admin",
		  token: ""
	  },
	  dicts: null
    }
  },
  mutations: {
    setUserInfo (state, info) {
      state.userInfo = info
    },
	setDicts(state, info) {
		state.dicts = info
	}
  }
})

export default store