<template>
	<div class="menu-view">
		<el-menu
			active-text-color="#ffd04b"
			background-color="#545c64"
			class="el-menu-vertical-demo"
			default-active="2"
			text-color="#fff"
			:router="true"
			:default-active="defaultActive"
			@select="handleOpen">
			<el-menu-item index="/main/home">
			  <el-icon><icon-menu /></el-icon>
			  <span>首页</span>
			</el-menu-item>
			<el-menu-item index="/main/order">
			  <el-icon><icon-menu /></el-icon>
			  <span>订单</span>
			</el-menu-item>
			<el-menu-item index="/main/users">
			  <el-icon><icon-menu /></el-icon>
			  <span>用户列表</span>
			</el-menu-item>
			<!-- <el-menu-item index="3">
			  <el-icon><setting /></el-icon>
			  <span>修改密码{{$route.path}}</span>
			</el-menu-item> -->
		</el-menu>
	</div>
</template>

<script>
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from '@element-plus/icons-vue'
import {mapState} from 'vuex'

export default {
	data() {
		return {
			
		}
	},
	computed: {
		defaultActive() {
			return this.$route.path
		}
	},
	mounted() {
		
	},
    methods: {
		handleOpen(key, keyPath) {
			
		}
    }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variable.scss';
.menu-view{
	width: $menuWidth;
	position: fixed;
	top: $headerBarH;
	bottom: 0;
	background-color: #545c64;
	.el-menu{
		border-right: none;
	}
}
</style>
