<template>
	<el-row class="header-content">
		<el-col :span="12">
			<img class="logo" src="~@/assets/images/logo.jpeg">
			<h3 class="header-title">广东鑫粤拍卖有限公司后台管理系统</h3>
		</el-col>
	    <el-col :span="12" style="text-align: right;">
		  <el-dropdown class="header-dropdown" @command="handleCommand">
		      <span class="el-dropdown-link">
		        {{this.userInfo.name}}
		        <el-icon class="el-icon--right">
		          <arrow-down />
		        </el-icon>
		      </span>
		      <template #dropdown>
		        <el-dropdown-menu>
		          <!-- <el-dropdown-item command="a">账号管理</el-dropdown-item>
		          <el-dropdown-item command="b">修改密码</el-dropdown-item> -->
		          <el-dropdown-item divided command="logOut">退出</el-dropdown-item>
		        </el-dropdown-menu>
		      </template>
		    </el-dropdown>
	    </el-col>
	</el-row>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import SystemSta from '../../constants/index'
import { ArrowDown } from '@element-plus/icons-vue'
export default {
	components: {
		ArrowDown
	},
	data() {
		return {
			
		}
	},
	computed: {
		  ...mapState(['userInfo'])
	},
	methods: {
		...mapMutations(['LOG_OUT']),
		handleCommand(comman) {
			if(comman == "logOut") {
				this.$store.commit("setUserInfo",{name: "", token: ""})
				this.$router.push("/login")
			} 
		}
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/variable.scss';
// 顶部TopBar组件样式
.header-content{
    width: 100%;
    height: $headerBarH;
	line-height: $headerBarH;
    overflow: hidden;
    background: #fff;
    box-shadow:0px 2px 2px 0px rgba(0, 0, 0, 0.1);
	padding: 0 20px;
	box-sizing: border-box;
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 3;
    .logo{
        height: 50px;
        float: left;
        margin-top: 10px;
    }
    .header-title{
        line-height: 70px;
        font-size: 26px;
        color: #333;
        padding-left: 20px;
        box-sizing: border-box;
        float: left;
    }
	.header-dropdown{
		margin-top: 30px;
		.el-dropdown-link{
			outline: none;
		}
	}
}
</style>