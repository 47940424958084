import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from '@/router'
import store from '@/store'

import Components from './components/index'; //注册自定义组件
import ElementPlugins from './plugins'; // 引入全局Element插件

createApp(App)
.use(router)
.use(store)
.use(ElementPlus)
.use(ElementPlugins)
.use(Components)
.mount('#app')
