import { createRouter,createWebHashHistory } from "vue-router";
import store from '../store/index' //Vuex

const BaseLayout = () => import("@/components/baseLayout/BaseLayout.vue")
const LoginPage = () => import("@/views/login/LoginPage.vue")
const HomePage = () => import("@/views/home/HomePage.vue")
const OrderPage = () => import("@/views/home/OrderPage.vue")
const UsersPage = () => import("@/views/home/UsersPage.vue")
const CarInfo = () => import("@/views/home/CarInfo.vue")

const routes = [
	{
		path: '/',
		redirect: '/login'
	},
	{
		path: '/login',
		name: 'login',
		component: LoginPage
	},
	{
		path: '/main',
		name: 'main',
		component: BaseLayout,
		children: [
			{
				path: 'home',
				name: 'home',
				component: HomePage
			},
			{
				path: 'order',
				name: 'order',
				component: OrderPage
			},
			{
				path: 'users',
				name: 'users',
				component: UsersPage
			},
			{
				path: 'carInfo',
				name: 'carInfo',
				component: CarInfo
			}
		]
	}
]

const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes, // `routes: routes` 的缩写
})

// 路由守卫
router.beforeEach((to, form, next) => {
    //设置页面标题
    document.title = to.meta.title ? to.meta.title + '- 广东鑫粤拍卖有限公司后台管理系统' : '广东鑫粤拍卖有限公司后台管理系统'; 
    // 判断是不是跳转到登录页
	console.log(to.name)
    if(to.name == 'login'){ 
        // 如果已登录则不会跳转到登录页
		console.log(store.state.userInfo.token)
        if(store.state.userInfo.token){
            next('/main/home')
        }else{
            next()
        }
    }else{
        // 未登录无法打开除登录页以外的页面
        if(store.state.userInfo.token){
            next()
        }else{
            next('/')
        }
    }
	// next()
})

export default router